import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FiltersComponent, IAgGridColGroupDef, SortableComponent, ValueFormatters} from "swx.front-end-lib";
import {ApiService} from "../shared/Api.service";
import {ControlContainer, NgModelGroup} from "@angular/forms";
import {Field, FieldGroup} from "swx.front-end-lib/lib/grid/Query.model";

@Component({
    selector: 'service-monitoring-condition-list',
    templateUrl: 'ServiceMonitoringConditionList.component.html',
    styleUrls: ['./ServiceMonitoringConditionList.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }]
})
export class ServiceMonitoringConditionListComponent implements OnInit {
    @Input() conditions;
    @Input() parent;
    @Input() parentList: ServiceMonitoringConditionListComponent;
    @Input() ngForm;
    @ViewChild('sortable', { static: true }) sortable: SortableComponent;
    availableFields: Map<string, Field>;
    fieldGroups: FieldGroup[];
    ibmMqServerConfigurations = this.api.IBMMQServerConfiguration.query();
    columnDefs: IAgGridColGroupDef[] = [
        {
            headerName: "Previous alert",
            children: [
                { field: "PreviousMonitoringAlertAgeMinutes", headerName: "Age (minutes)", filterType: "float" },
            ]
        },
        {
            headerName: "Coordinator",
            children: [
                { field: "CoordinatorServiceRunning", headerName: "Running", filterType: "boolean" },
                { field: "CoordinatorServiceMemoryUsageMB", headerName: "RAM (MB)", filterType: "float", },
                { field: "ServiceStatus.CoordinatorService.CPUUsage", headerName: "CPU (%)", filterType: "float" },
            ]
        },
        {
            headerName: "Data collector",
            children: [
                { field: "DataCollectorServiceRunning", headerName: "Running", filterType: "boolean" },
                { field: "DataCollectorServiceMemoryUsageMB", headerName: "RAM (MB)", filterType: "float" },
                { field: "ServiceStatus.DataCollectorService.CPUUsage", headerName: "CPU (%)", filterType: "float" },
                { field: "LastMetarReadingUpdateAgeMinutes", headerName: "Last METAR update age (minutes)", filterType: "float" },
                { field: "LastSureForecastAllClearAgeMinutes", headerName: "Last Sureforecast AllClear valid from age (minutes)", filterType: "float" },
                { field: "ServiceStatus.DataCollectorService.SureForecastAllClearAirportPercentage", headerName: "Sureforecast AllClear airport coverage (%)", filterType: "float" },
                { field: "LastNowcast12AgeMinutes", headerName: "Last Nowcast LWE12 valid from age (minutes)", filterType: "float" },
                { field: "ServiceStatus.DataCollectorService.Nowcast12AirportPercentage", headerName: "Nowcast LWE12 airport coverage (%)", filterType: "float" },
                { field: "LastOpenMeteoForecastUpdateAgeMinutes", headerName: "Last NOAA RAP report update age (minutes)", filterType: "float" },
                { field: "LastNoaaRapReportUpdateAgeMinutes", headerName: "Last Open-Meteo forecast update age (minutes)", filterType: "float" },
            ],
        },
        {
            headerName: "Communicator",
            children: [
                { field: "CommunicatorServiceRunning", headerName: "Running", filterType: "boolean" },
                { field: "CommunicatorServiceMemoryUsageMB", headerName: "RAM (MB)", filterType: "float" },
                { field: "ServiceStatus.CommunicatorService.CPUUsage", headerName: "CPU (%)", filterType: "float" },
                { field: "FedExShiftAgeHours", headerName: "Last Fedex Shift Received Age (hours)", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.AcarsHotResponseTimeMs", headerName: "ACARS HOT response time (ms)", filterType: "float" },
                { field: "ServiceStatus.CommunicatorService.AcarsHotOnline", headerName: "ACARS HOT response online", filterType: "boolean" },
            ]
        },
        {
            headerName: "Portal",
            children: [
                { field: "ClientPortalsWebServiceRunning", headerName: "Running", filterType: "boolean" },
                { field: "ClientPortalsWebServiceMemoryUsageMB", headerName: "RAM (MB)", filterType: "float" },
                { field: "ServiceStatus.ClientPortalsWebService.CPUUsage", headerName: "CPU (%)", filterType: "float" },
                { field: "ServiceStatus.ClientPortalsWebService.ClientPortalHotResponseTimeMs", headerName: "HOT response time (ms)", filterType: "float" },
                { field: "ServiceStatus.ClientPortalsWebService.ClientPortalHotOnline", headerName: "HOT response online", filterType: "boolean" },
            ]
        },
        {
            headerName: "Mobile API",
            children: [
                { field: "ClientWebServiceRunning", headerName: "Running", filterType: "boolean" },
                { field: "ClientWebServiceMemoryUsageMB", headerName: "RAM (MB)", filterType: "float" },
                { field: "ServiceStatus.ClientWebService.CPUUsage", headerName: "CPU (%)", filterType: "float" },
                { field: "ServiceStatus.ClientWebService.MobileHotResponseTimeMs", headerName: "HOT response time (ms)", filterType: "float" },
                { field: "ServiceStatus.ClientWebService.MobileHotOnline", headerName: "HOT response online", filterType: "boolean" },
                { field: "ServiceStatus.ClientWebService.MobileConfigurationResponseTimeMs", headerName: "Config response time (ms)", filterType: "float" },
                { field: "ServiceStatus.ClientWebService.MobileConfigurationOnline", headerName: "Mobile configuration online", filterType: "boolean" },
            ]
        },
        {
            headerName: "Admin",
            children: [
                { field: "ConfigurationWebServiceRunning", headerName: "Running", filterType: "boolean" },
                { field: "ConfigurationWebServiceMemoryUsageMB", headerName: "RAM (MB)", filterType: "float" },
                { field: "ServiceStatus.ConfigurationWebService.CPUUsage", headerName: "CPU (%)", filterType: "float" },
            ]
        },
        {
            headerName: "DDMS",
            children: [
                { field: "DeicingManagementWebServiceRunning", headerName: "Running", filterType: "boolean" },
                { field: "DeicingManagementWebServiceMemoryUsageMB", headerName: "RAM (MB)", filterType: "float" },
                { field: "ServiceStatus.DeicingManagementWebService.CPUUsage", headerName: "CPU (%)", filterType: "float" },
            ]
        },
        {
            headerName: "Service provider API",
            children: [
                { field: "ServiceProviderWebServiceRunning", headerName: "Running", filterType: "boolean" },
                { field: "ServiceProviderWebServiceMemoryUsageMB", headerName: "RAM (MB)", filterType: "float" },
                { field: "ServiceStatus.ServiceProviderWebService.CPUUsage", headerName: "CPU (%)", filterType: "float" },
            ]
        },
        {
            headerName: "Queue depths",
            children: [
                { field: "ServiceStatus.CommunicatorService.ACARSMessageQueueDepth", headerName: "ACARS", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.XMLMessageQueueDepth", headerName: "XML", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.AIDXMessageQueueDepth", headerName: "AIDX", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.EmailQueueDepth", headerName: "Email", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.FlightAwareFirehoseQueueDepth", headerName: "Flight aware", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.JMSQueueDepth", headerName: "JMS", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.SQSQueueDepth", headerName: "SQS", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.SFTPServerQueueDepth", headerName: "SFTP", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.BarrelIcingWarningTickQueueDepth", headerName: "Barrel icing", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.ActiveFrostWarningTickQueueDepth", headerName: "Active frost", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.EngineCoverAlertTickQueueDepth", headerName: "Engine cover", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.HeatFlapsAlertQueueDepth", headerName: "Heat flaps alerts AIDX events", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.HeatFlapsAlertTickQueueDepth", headerName: "Heat flaps alerts ticks", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.FlapContaminationAlertQueueDepth", headerName: "Flap contamination alerts AIDX events", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.FlapContaminationAlertTickQueueDepth", headerName: "Flap contamination alerts ticks", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.FreezingFogAlertQueueDepth", headerName: "FZFG alerts OOOIs", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.FreezingFogAlertTickQueueDepth", headerName: "FZFG alerts ticks", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.WeatherAlertQueueDepth", headerName: "Weather alert ticks", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.YvrWeatherHotQueueDepth", headerName: "YVR weather HOT ticks", filterType: "integer" },
                { field: "ServiceStatus.DataCollectorService.MetarReadingUpdateQueueDepth", headerName: "METAR", filterType: "integer" },
            ]
        },
    ];
    
    constructor(
        private formatters: ValueFormatters,
        private api: ApiService,
    ) {
    }

    ngOnInit() {
        this.ibmMqServerConfigurations.$promise.then(() => {
            let columnDefs = this.columnDefs.concat(this.ibmMqServerConfigurations.map(c => ({
                headerName: `IBM MQ configuration (${c.Name})`,
                children: [
                    { field: `IBMMQServerConnectionInfos[${c.Id}].LastMessageAgeMinutes`, headerName: "Last message (minutes)", filterType: "float", },
                ]
            })));
            this.availableFields = FiltersComponent.mapColumnDefs(this.api, columnDefs);
            this.fieldGroups = FiltersComponent.getFieldGroups(columnDefs);
        });
    }
    
    addCondition(parent, type: string) {
        var maxOrder = Math.max.apply(null, this.conditions.filter(c => c.ParentConditionId == this.parent?.Id)
            .map(h => h.Order));
        this.conditions.push({
            Id: ServiceMonitoringConditionListComponent.uuidv4(),
            ParentConditionId: this.parent?.Id,
            Order: Math.max(1, maxOrder + 1),
            ConditionType: type,
            MinimumDurationMinutes: 1,
        });
        this.ngForm.form.markAsDirty();
    }

    //https://stackoverflow.com/a/2117523/530630
    static uuidv4() {
        return `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, c =>
            (parseInt(c) ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> parseInt(c) / 4).toString(16)
        );
    }
    
    childConditions() {
        return this.conditions?.filter(c => c.ParentConditionId == this.parent?.Id) ?? [];
    }

    removeServiceMonitoringCondition(parent, condition) {
        this.conditions.splice(this.conditions.indexOf(condition), 1);
        this.ngForm.form.markAsDirty();
    }
}
